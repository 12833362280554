footer {
  left: 0;
  bottom: 0;
  width: 100%;
  color: white;
  background-color: #373a48;
  position: fixed;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 18px;
}

footer .social-networks .fa-github {
  transition: 0.2s;
  text-decoration: none;
  font-size: 2.5em;
  color: white;
  &:hover {
    color: #00aced;
  }
}
